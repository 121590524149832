import { Component, OnInit, ViewChild, ElementRef, NgZone, Output, EventEmitter, Input, OnChanges, SimpleChanges, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IdeateValidators } from '../../services/ideate';
import { MapsAPILoader } from '@agm/core';

@Component({
	selector: 'app-google-map-search',
	templateUrl: './google-map-search.component.html',
	styleUrls: ['./google-map-search.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class GoogleMapSearchComponent implements OnInit, OnChanges, OnDestroy {

	public zoom: number;
	public formAddressSearch: FormGroup;
	/* private geoCoder: any; */
	public validationMessages: any = {
		address: {
			required: 'Address is required.'
		}
	};
	public showMap: boolean;
	public map: any;
	@Input() latitude: number;
	@Input() longitude: number;
	@Input() address: string;
	@Input() optional: boolean;
	@Output() addressChanged = new EventEmitter<any>();
	@Output() latLongChanged = new EventEmitter<any>();
	@ViewChild('search')
	public searchElementRef: ElementRef;
	public isFormSubmitted = false;

	constructor(private formBuilder: FormBuilder,
		private customValidators: IdeateValidators, private mapsAPILoader: MapsAPILoader,
		private ngZone: NgZone) { }

	ngOnInit() {

		this.showMap = false;
		this.zoom = 13;

		if (this.optional == undefined) {
			this.optional = false;
		}

		// load Places Autocomplete
		this.mapsAPILoader.load().then(() => {
			// this.setCurrentLocation();

			/* this.geoCoder = new google.maps.Geocoder; */

			const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
				types: ['address'],
				bounds: new google.maps.LatLngBounds(
					new google.maps.LatLng(24.9493, -125.0011),
					new google.maps.LatLng(49.5904, -66.9326)
				)
			});
			autocomplete.addListener('place_changed', () => {

				this.ngZone.run(() => {
					// get the place result
					const place: google.maps.places.PlaceResult = autocomplete.getPlace();

					// verify result
					if (!place || !place.geometry) {
						this.formAddressSearch.controls['address'].setValue('');
						return;
					}

					// Setting address value from search input element as formatted address received in place object could be different than the selected one in auto suggestion
					this.address = this.searchElementRef.nativeElement.value;

					this.latitude = place.geometry.location.lat();
					this.longitude = place.geometry.location.lng();
					this.formAddressSearch.controls['address'].setValue(this.address);
					this.addressChanged.emit(this.address);
					this.latLongChanged.emit({ latitude: this.latitude, longitude: this.longitude });

					// this.getAddress(this.latitude, this.longitude);
				});
			});
		});

		// this.setCurrentLocation();
		this.initForm();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if ('latitude' && 'longitude' in changes && this.latitude && this.longitude) {
			this.setMapCenter();
			this.initForm();
		}
		else if ('address' in changes && this.address) {
			setTimeout(async () => {
				while (true) {
					if (typeof google != 'undefined') {
						let geocoder = new google.maps.Geocoder();
						geocoder.geocode({ "address": this.address }, (results, status) => {
							if (status == google.maps.GeocoderStatus.OK) {
								this.latitude = results[0].geometry.location.lat();
								this.longitude = results[0].geometry.location.lng();
								this.address = results[0].formatted_address;
								this.setMapCenter();
								this.initForm();
								this.formAddressSearch.controls['address'].setValue(this.address);
								this.addressChanged.emit(this.address);
								this.latLongChanged.emit({ latitude: this.latitude, longitude: this.longitude });
							}
						});
						break;
					} else {
						await new Promise(f => setTimeout(f, 1000));
					}
				}
			});
		}
	}

	setMapCenter() {
		if (this.latitude && this.longitude && this.map) {
			this.map.setCenter({ lat: +this.latitude, lng: +this.longitude });
		}
	}

	/* private setCurrentLocation() {
		if ('geolocation' in navigator) {
			navigator.geolocation.getCurrentPosition((position) => {
				this.latitude = position.coords.latitude;
				this.longitude = position.coords.longitude;
				this.zoom = 8;
			});
		}
	} */

	mapReady(map) {
		this.map = map;
	}

	/*markerDragEnd($event: any) {
		this.latitude = $event.coords.lat;
		this.longitude = $event.coords.lng;
		this.latLongChanged.emit({ latitude: this.latitude, longitude: this.longitude });
	} */

	/* getAddress(latitude: number, longitude: number) {
		this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results: any, status: any) => {
			if (status === 'OK') {
				console.log(results);
				if (results[0]) {
					this.zoom = 12;
					this.address = results[0].formatted_address;
					this.addressChanged.emit(this.address);
					this.latLongChanged.emit({ latitude: latitude, longitude: longitude });
				} else {
					console.log('No results found');
				}
			} else {
				console.log('Failed to get address due to: ' + status);
			}
		});
	} */

	initForm() {
		this.formAddressSearch = this.formBuilder.group({
			address: [this.address, Validators.compose(!this.optional ? [Validators.required] : [])]
		});

		this.formAddressSearch.statusChanges.subscribe((data) => {
			if (this.formAddressSearch.controls['address'].value === '') {
				this.addressChanged.emit(this.formAddressSearch.controls['address'].value);
			}
			this.validateForm();
		});

		this.validateForm();
	}

	evKeyDown($event) {
		if ($event.keyCode !== 9) {
			this.latitude = 0;
			this.longitude = 0;
		}
	}

	evAddressBlur() {
		setTimeout(() => {
			if (this.latitude === 0 && this.longitude === 0) {
				this.formAddressSearch.controls['address'].setValue('');
			}
		}, 2000);
	}

	validateForm() {
		this.validationMessages = this.customValidators.getValidationErrors(this.formAddressSearch, this.validationMessages, this.isFormSubmitted);
	}

	submitForm() {
		this.isFormSubmitted = true;

		if (this.formAddressSearch.invalid) {
			this.searchElementRef.nativeElement.focus();
		}

		this.validateForm();
	}

	ngOnDestroy() {
		this.isFormSubmitted = false;
	}

}
