import { Injectable } from '@angular/core';
import { IdeateHelper } from '../ideate';
import { Account } from '../../../providers/account';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class TicketService {


	public selectedTicketUpdateEvent = new Subject<any>();
	selectedTicketUpdateEvent$ = this.selectedTicketUpdateEvent.asObservable();

	constructor(
		private account: Account,
		private helper: IdeateHelper
	) { }

	emitSelectedTicketUpdateEvent(message: string) {
		this.selectedTicketUpdateEvent.next(message);
	}

	ticketRequestAction(requestId: number, status: string, note: string = '', actionThread: any = {}) {
		return new Promise(resolve => {
			const reqParams: any = {};
			reqParams.user_id = this.account.info.id;
			reqParams.auth_token = this.account.info.auth_token;

			reqParams.ticketId = actionThread.id;
			reqParams.requestId = requestId;
			reqParams.status = status;
			reqParams.type = actionThread.ticket_request_type;
			reqParams.note = note;

			this.helper.makeAPIRequest('ticket_requests/approve_reject', reqParams).then((response) => {
				if (response.success === 1) {
					this.helper.showNotification('success', 'Ticket change request updated!', this.helper.config.defaultSuccessTitle);
					return resolve(response);
				} else if (response.error === 1) {
					return resolve(response);
				} else {
					this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
					return resolve(false);
				}
			}).catch((httpError) => {
				this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
				return resolve(false);
			});
		});
	}
}
