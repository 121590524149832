import { Injectable } from '@angular/core';
import { IdeateHelper } from '../ideate';
import { Account } from '../../../providers/account';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class TicketService {


	public selectedTicketUpdateEvent = new Subject<any>();
	selectedTicketUpdateEvent$ = this.selectedTicketUpdateEvent.asObservable();

	constructor(
		private account: Account,
		private helper: IdeateHelper
	) { }

	emitSelectedTicketUpdateEvent(message: string) {
		this.selectedTicketUpdateEvent.next(message);
	}

	ticketRequestAction(requestId: number, status: string, note: string = '', actionThread: any = {}) {
		return new Promise(resolve => {
			const reqParams: any = {};
			reqParams.user_id = this.account.info.id;
			reqParams.auth_token = this.account.info.auth_token;

			reqParams.ticketId = actionThread.id;
			reqParams.requestId = requestId;
			reqParams.status = status;
			reqParams.type = actionThread.ticket_request_type;
			reqParams.note = note;

			this.helper.makeAPIRequest('ticket_requests/approve_reject', reqParams).then((response) => {
				if (response.success === 1) {
					this.helper.showNotification('success', 'Ticket change request updated!', this.helper.config.defaultSuccessTitle);
					return resolve(response);
				} else if (response.error === 1) {
					return resolve(response);
				} else {
					this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
					return resolve(false);
				}
			}).catch((httpError) => {
				this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
				return resolve(false);
			});
		});
	}

	getBillingManagers() {
		return new Promise(resolve => {
			const reqParams: any = {};
			reqParams.user_id = this.account.info.id;
			reqParams.auth_token = this.account.info.auth_token;
			reqParams.billing_managers_only = 1;
			this.helper.makeAPIRequest('customers/lookup', reqParams).then(result => {
				if (result.success === 1) {
					return resolve(result.data.record);
				} else {
					return resolve([]);
				}
			});
		})
	}

	getServices() {
		return new Promise(resolve => {
			const reqParams: any = {};
			reqParams.user_id = this.account.info.id;
			reqParams.auth_token = this.account.info.auth_token;
			this.helper.makeAPIRequest('services/lookup', reqParams).then(result => {
				if (result.success === 1) {
					return resolve(result.data.record);
				} else {
					return resolve([]);
				}
			});
		})
	}

	getTicket(ticketId) {
		return new Promise(resolve => {
			const reqParams: any = {};
			reqParams.user_id = this.account.info.id;
			reqParams.auth_token = this.account.info.auth_token;
			reqParams.record_id = ticketId;
			this.helper.makeAPIRequest('tickets/get', reqParams).then((response) => {
				resolve(response);
			}).catch((httpError) => {
				console.log(httpError)
				resolve(false);
			});
		});
	}

	approvalTicket(approvalParams: number) {
		return new Promise(resolve => {
			this.helper.makeAPIRequest('tickets/approval', approvalParams).then((response) => {
				resolve(response);
			}).catch((httpError) => {
				console.log(httpError)
				resolve(false);
			});
		});
	}


	exportTicket(dtRequestParameters) {
		this.helper.makeAPIRequest('tickets/export', dtRequestParameters).then((result) => {
			if (result.success === 1) {
				const hiddenElement = document.createElement('a');

				hiddenElement.href = result.data.path;
				hiddenElement.click();
			} else if (result.error === 1) {
				if (result.errorCode === 2) {
					this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
					this.account.logOut();
				} else if (result.errorCode === 3) {
					this.helper.showNotification('danger', this.helper.config.defaultNoResultErrorMsg, this.helper.config.defaultErrorTitle);
				} else if (result.errorCode === 4) {
					this.helper.showNotification('danger', 'Failed to generate the CSV.', this.helper.config.defaultErrorTitle);
				} else {
					this.helper.showNotification('danger', 'API_ERROR ' + result.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
				}
			} else {
				this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
			}
		}).catch((httpError) => {
			this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
		});
	}
}
