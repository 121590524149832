import { Pipe, PipeTransform } from '@angular/core';
import { IdeateHelper } from '../core/services/ideate';

@Pipe({
	name: 'serviceNames2'
})
export class ServiceNamesPipe2 implements PipeTransform {

	constructor(private helper: IdeateHelper) { }

	transform(value: any, args?: any): any {
		const servicesSelected = [];
		if (value && value.length > 0) {

			const newFormattedValue = [];
			value.forEach(item => {
				item.service_id = item.id ? item.id : item.service_id
				newFormattedValue.push(item);
			});

			for (const item of newFormattedValue) {
				const serviceSelectedIdx = this.helper.getArrayIndex(servicesSelected, 'service_id', item.service_id);
				let count = 0;
				if (item.hasOwnProperty('count')) {
					count = item.count;
				}

				if (serviceSelectedIdx === false) {
					const serviceSelected = { service_id: item.service_id, title: item.title, qty: 1, count };
					servicesSelected.push(serviceSelected);
				} else {
					servicesSelected[serviceSelectedIdx].qty++;
				}
			}
		}

		for (const item of servicesSelected) {
			if (item.count > 0) {
				item.qty = item.count;
			}
		}

		const serviceNames = [];
		if (servicesSelected.length > 0) {
			for (const service of servicesSelected) {
				let serviceName = service.title;
				if (service.qty > 1) {
					serviceName += ' (' + service.qty + ')';
				}
				serviceNames.push(serviceName);
			}
		}
		return (serviceNames && serviceNames.length > 0) ? serviceNames.join(', ') : '';
	}

}
