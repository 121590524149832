import { Injectable } from '@angular/core';
import Pusher from 'pusher-js';
import { IdeateHelper } from '../ideate';

@Injectable({
	providedIn: 'root',
})
export class PusherService {
	private pusher: Pusher;

	constructor(
		public helper: IdeateHelper) {
		// Initialize Pusher with your app key and cluster
		this.pusher = new Pusher(helper.config.PUSHER_API_KEY, {
			cluster: helper.config.PUSHER_API_CLUSTER,
		});
	}

	/**
	 * Subscribe to a specific channel.
	 * @param channelName The name of the channel to subscribe to.
	 * @returns The Pusher channel object.
	 */
	subscribe(channelName: string) {
		return this.pusher.subscribe(channelName);
	}

	/**
	 * Unsubscribe from a specific channel.
	 * @param channelName The name of the channel to unsubscribe from.
	 */
	unsubscribe(channelName: string) {
		this.pusher.unsubscribe(channelName);
	}

	/**
	 * Bind an event to a channel.
	 * @param channel The channel object.
	 * @param eventName The name of the event to listen for.
	 * @param callback The callback function to execute when the event is triggered.
	 */
	bindEvent(channel: any, eventName: string, callback: (data: any) => void) {
		channel.bind(eventName, callback);
	}

	/**
	 * Unbind an event from a channel.
	 * @param channel The channel object.
	 * @param eventName The name of the event to unbind.
	 */
	unbindEvent(channel: any, eventName: string) {
		channel.unbind(eventName);
	}
}
