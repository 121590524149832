import { Component, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import 'rxjs/add/operator/switchMap';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { GoogleMapSearchComponent } from '../core/components/google-map-search/google-map-search.component';
import { IdeateHelper, IdeateValidators } from '../core/services/ideate';
import { Account } from '../providers/account';
import { App } from '../providers/app';

@Component({
	selector: 'app-contracts-entry',
	templateUrl: './contracts-entry.component.html',
	styleUrls: ['./contracts-entry.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ContractsEntryComponent implements OnInit {

	public onClose: Subject<any>;
	public frmAddEdit: FormGroup;
	public mode = 'new';
	public currentContractID = 0;
	public record: any = {};
	public selectedCustomer: any;
	public services: Observable<any[]> | any;
	public servicesSelected: any;
	public servicesAddOns: Observable<any[]> | any;
	public servicesAddOnsSelected: any;
	public billingManagers: Observable<any[]> | any;
	public billingManagerSelected: any;
	public customersSuggestions = [];
	public isProcessing = false;
	public isFormSubmitted = false;
	public mergeSourceCustomer: any = {};
	public mergeTargetCustomer: any = {};
	@ViewChild('confirmCustomerMerge') confirmCustomerMerge: ModalDirective;
	@ViewChild(GoogleMapSearchComponent) googleMapSearchComponent: GoogleMapSearchComponent;
	@ViewChildren('phoneInputs') phoneInputs: QueryList<any>;
	@ViewChildren('emailInputs') emailInputs: QueryList<any>;
	bsModalRefEntry: BsModalRef;
	public validationMessages: any = {
		services: {
		},
		services_add_ons: {
		},
		customer: {
		},
		name: {
			required: 'Name is required.'
		},
		customerAddress: {
			required: 'Address is required.'
		},
		customerApartment: {
			required: 'Apartment is required.'
		},
		customerLatitude: {
			required: 'Latitude is required.'
		},
		customerLongitude: {
			required: 'Longitude is required.'
		},
		customerBillingManager: {},
		additional_email: {
			email: 'Invalid Email.',
		},
		address: {
			required: 'Address is required.'
		},
		apartment: {
			required: 'Apartment is required.'
		},
		latitude: {
			required: 'Latitude is required.'
		},
		longitude: {
			required: 'Longitude is required.'
		},
		priority: {
			required: 'Priority is required.'
		},
		contract_start_date: {
		},
		service_time_from: {
		},
		service_time_to: {
		},
		contract_service_cycles: {
			required: 'Please enter the number of cycles for the contract.',
			min: 'Minimum allowed value is 1.',
			max: 'Maximum allowed value is 365.'
		},
		base_price: {
		},
		suggested_price: {
		},
		asked_price: {
		},
		billing_type: {
		},
		billing_status: {
		},
		billing_manager: {
		},
		caller_id: {
		},
		note: {
		}
	};

	constructor(
		private formBuilder: FormBuilder,
		public helper: IdeateHelper,
		private customValidators: IdeateValidators,
		public account: Account,
		public bsModalRef: BsModalRef,
		public app: App
	) { }

	ngOnInit() {
		this.getRecordData();
		this.onClose = new Subject();
	}

	getRecordData() {
		if (this.mode === 'new') {
			this.initForm();
		} else if (this.mode === 'renew' || this.mode === 'edit') {
			const reqParams: any = {};
			reqParams.user_id = this.account.info.id;
			reqParams.auth_token = this.account.info.auth_token;
			reqParams.record_id = this.currentContractID;
			this.helper.makeAPIRequest('contracts/get', reqParams).then((response) => {
				if (response.success === 1) {
					this.record = response.data.record;
					if (this.mode === 'renew') {
						this.record.id = 0;
						this.record.contract_start_date = '';
					}
					this.initForm();
				} else if (response.error === 1) {
					if (response.errorCode === 2) {
						this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
						this.account.logOut();
					} else if (response.errorCode === 4) {
						this.helper.showNotification('danger', this.helper.config.defaultNoResultErrorMsg, this.helper.config.defaultErrorTitle);
						this.helper.navBack();
					} else {
						this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
					}
				} else {
					this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
				}
			}).catch((httpError) => {
				console.log(httpError);
				this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
			});
		}
	}

	initForm() {
		this.frmAddEdit = this.formBuilder.group({
			services: [(this.record.services && this.record.services.length > 0) ? this.record.services.map((item) => item.id.toString()) : []],
			services_add_ons: [(this.record.services_add_ons && this.record.services_add_ons.length > 0) ? this.record.services_add_ons.map((item) => item.id.toString()) : []],
			customer: [this.record.customer],
			name: [this.record.name, Validators.compose([Validators.required])],
			email: this.formBuilder.array([]),
			phone: this.formBuilder.array([]),
			customerAddress: [this.record.customerAddress, Validators.compose([Validators.required])],
			customerApartment: [this.record.customerApartment, Validators.compose([Validators.required])],
			customerLatitude: [this.record.customerLatitude, Validators.compose([Validators.required])],
			customerLongitude: [this.record.customerLongitude, Validators.compose([Validators.required])],
			customerBillingManager: [this.helper.getObjVal(this.record, ['customerBillingManager', 'id'], null)],
			additional_email: [this.record.additional_email, Validators.compose([Validators.email])],
			address: [this.record.address, Validators.compose([Validators.required])],
			apartment: [this.record.apartment, Validators.compose([Validators.required])],
			latitude: [+this.record.latitude, Validators.compose([Validators.required])],
			longitude: [+this.record.longitude, Validators.compose([Validators.required])],
			priority: [this.record.priority ? this.record.priority : 'normal', Validators.compose([Validators.required])],
			contract_start_date: [(this.record.contract_start_date && this.record.contract_start_date !== '' && this.record.contract_start_date !== '0000-00-00') ? this.helper.dtFormatFromStrToObj(this.helper.dFormatFromStr(this.record.contract_start_date)) : new Date()],
			service_time_from: [this.record.service_time_from ? this.helper.tFormatFromStr(this.record.service_time_from) : this.helper.tFormatFromStr('00:00')],
			service_time_to: [this.record.service_time_to ? this.helper.tFormatFromStr(this.record.service_time_to) : this.helper.tFormatFromStr('00:00')],
			contract_service_cycles: [this.record.contract_service_cycles ? this.record.contract_service_cycles : '', Validators.compose([Validators.required, Validators.min(1), Validators.max(365)])],
			base_price: [this.record.base_price],
			suggested_price: [this.record.suggested_price],
			asked_price: [this.record.asked_price],
			billing_type: [(this.record.billing_type && this.record.billing_type !== '') ? this.record.billing_type : 'fixed'],
			billing_status: [(this.record.billing_status && this.record.billing_status !== '') ? this.record.billing_status : 'yc_collect'],
			billing_manager: [this.helper.getObjVal(this.record, ['billing_manager', 'id'], '')],
			caller_id: [this.record.caller_id],
			note: [this.record.contract_note],
		});

		// Set billing manager note if billing manage details are available.
		if (this.record.billing_manager) {
			this.onBillingManagerChanged(this.record.billing_manager.id);
		}

		this.selectedCustomer = this.record.customer;

		if (this.record.customer && this.record.customer.id) {
			this.bindCustomerDetailsToContract(this.record.customer);
		} else {
			this.addPhoneFieldGroup();
			this.addEmailFieldGroup();
		}

		this.frmAddEdit.get('services').valueChanges.subscribe(() => {
			this.updatedSelectedServicesList();
			this.updatePrices();
		});
		this.updatedSelectedServicesList();

		this.frmAddEdit.get('services_add_ons').valueChanges.subscribe(() => {
			this.updatedSelectedServicesAddOnsList();
			this.updatePrices();
		});
		this.updatedSelectedServicesAddOnsList();

		this.frmAddEdit.get('billing_type').valueChanges.subscribe(() => {
			this.updatePrices();
		});

		this.frmAddEdit.get('billing_status').valueChanges.subscribe(() => {
			this.updatePrices();
		});

		if (this.mode == 'renew') {
			this.updatePrices();
		}

		this.frmAddEdit.get('customerBillingManager').valueChanges.subscribe((result) => {
			this.onCustomerBillingManagerChanged(result);
		});

		this.frmAddEdit.get('name').valueChanges.subscribe(result => {
			this.frmAddEdit.controls['customer'].setValue(result);
		});

		this.frmAddEdit.statusChanges.subscribe(() => {
			this.validateForm();
		});

		this.validateForm();
	}

	initPhoneFieldGroup(phones: string[]) {
		this.removePhoneFieldGroup(-1);
		if (phones && phones.length > 0) {
			for (let i = 0; i < phones.length; i++) {
				const phone = phones[i];
				this.addPhoneFieldGroup(phone);
			}
		} else {
			this.addPhoneFieldGroup();
		}
	}

	addPhoneFieldGroup(phone: string = '') {
		const formControl = <FormArray>this.frmAddEdit.controls['phone'];
		formControl.push(this.formBuilder.group({
			phone: [phone],
		}));
	}

	removePhoneFieldGroup(idx: number) {
		if (idx >= 0) {
			const formControl = <FormArray>this.frmAddEdit.controls['phone'];
			formControl.removeAt(idx);
		} else {
			const formControl = <FormArray>this.frmAddEdit.controls['phone'];
			for (let i = 0; i < formControl.length; i++) {
				this.removePhoneFieldGroup(i);
			}
		}
	}


	initEmailFieldGroup(emails: string[]) {
		this.removeEmailFieldGroup(-1);
		if (emails && emails.length > 0) {
			for (let i = 0; i < emails.length; i++) {
				const email = emails[i];
				this.addEmailFieldGroup(email);
			}
		} else {
			this.addEmailFieldGroup();
		}
	}

	addEmailFieldGroup(email: string = '') {
		const formControl = <FormArray>this.frmAddEdit.controls['email'];
		formControl.push(this.formBuilder.group({
			email: [email],
		}));
	}

	removeEmailFieldGroup(idx: number) {
		if (idx >= 0) {
			const formControl = <FormArray>this.frmAddEdit.controls['email'];
			formControl.removeAt(idx);
		} else {
			const formControl = <FormArray>this.frmAddEdit.controls['email'];
			for (let i = 0; i < formControl.length; i++) {
				this.removeEmailFieldGroup(i);
			}
		}
	}


	addService(id: number) {
		const selectedServiceIDs = this.frmAddEdit.get('services').value;
		selectedServiceIDs.push(id);
		this.frmAddEdit.get('services').setValue(selectedServiceIDs);
	}

	removeService(id: number) {
		const selectedServiceIDs = this.frmAddEdit.get('services').value;
		if (selectedServiceIDs && selectedServiceIDs.length > 0) {
			const selectedServiceIdx = selectedServiceIDs.indexOf(id);
			if (selectedServiceIdx >= 0) {
				selectedServiceIDs.splice(selectedServiceIdx, 1);
				this.frmAddEdit.get('services').setValue(selectedServiceIDs);
				// this.removeService(id);
			}
		}
	}

	updatedSelectedServicesList() {
		this.servicesSelected = [];
		const selectedServiceIDs = this.frmAddEdit.get('services').value;
		if (this.services && this.services.length > 0) {
			for (const serviceID of selectedServiceIDs) {
				const serviceIdx = this.helper.getArrayIndex(this.services, 'id', serviceID);
				if (serviceIdx !== false) {
					const serviceSelectedIdx = this.helper.getArrayIndex(this.servicesSelected, 'id', serviceID);
					if (serviceSelectedIdx === false) {
						const service = this.services[serviceIdx];
						const serviceSelected = { id: service.id, title: service.title, qty: 1 };
						this.servicesSelected.push(serviceSelected);
					} else {
						this.servicesSelected[serviceSelectedIdx].qty++;
					}
				}
			}
		}
	}


	addServiceAddOn(id: number) {
		const selectedServiceIDs = this.frmAddEdit.get('services_add_ons').value;
		selectedServiceIDs.push(id);
		this.frmAddEdit.get('services_add_ons').setValue(selectedServiceIDs);
	}

	removeServiceAddOn(id: number) {
		const selectedServiceIDs = this.frmAddEdit.get('services_add_ons').value;
		if (selectedServiceIDs && selectedServiceIDs.length > 0) {
			const selectedServiceIdx = selectedServiceIDs.indexOf(id);
			if (selectedServiceIdx >= 0) {
				selectedServiceIDs.splice(selectedServiceIdx, 1);
				this.frmAddEdit.get('services_add_ons').setValue(selectedServiceIDs);
				// this.removeServiceAddOn(id);
			}
		}
	}

	updatedSelectedServicesAddOnsList() {
		this.servicesAddOnsSelected = [];
		const selectedServiceIDs = this.frmAddEdit.get('services_add_ons').value;
		if (this.servicesAddOns && this.servicesAddOns.length > 0) {
			for (const serviceID of selectedServiceIDs) {
				const serviceIdx = this.helper.getArrayIndex(this.servicesAddOns, 'id', serviceID);
				if (serviceIdx !== false) {
					const serviceSelectedIdx = this.helper.getArrayIndex(this.servicesAddOnsSelected, 'id', serviceID);
					if (serviceSelectedIdx === false) {
						const service = this.servicesAddOns[serviceIdx];
						const serviceSelected = { id: service.id, title: service.title, qty: 1 };
						this.servicesAddOnsSelected.push(serviceSelected);
					} else {
						this.servicesAddOnsSelected[serviceSelectedIdx].qty++;
					}
				}
			}
		}
	}

	validateForm() {
		this.validationMessages = this.customValidators.getValidationErrors(this.frmAddEdit, this.validationMessages, this.isFormSubmitted);
	}

	save(timeOutInterval: number = 0) {
		this.isProcessing = true;
		this.isFormSubmitted = true;
		this.googleMapSearchComponent.submitForm();
		this.validateForm();

		// This method will set focus on phone input if phone input validation fails
		this.setFocusOnInvalidPhoneInput();

		// This method will set focus on email input if email input validation fails
		this.setFocusOnInvalidEmailInput();

		setTimeout(() => {
			if (this.frmAddEdit.valid) {
				const userInputs: any = this.frmAddEdit.value;
				const reqParams: any = {};
				reqParams.user_id = this.account.info.id;
				reqParams.auth_token = this.account.info.auth_token;
				reqParams.record_id = (this.record && this.record.id) ? this.record.id : 0;
				reqParams.contract_renewed_for = (this.mode === 'renew' && this.currentContractID) ? this.currentContractID : 0;

				reqParams.additional_email = userInputs.additional_email;
				reqParams.address = userInputs.address;
				reqParams.apartment = userInputs.apartment;
				reqParams.latitude = userInputs.latitude;
				reqParams.longitude = userInputs.longitude;
				reqParams.priority = userInputs.priority;
				reqParams.caller_id = userInputs.caller_id;
				reqParams.services = (userInputs.services && userInputs.services.length > 0) ? userInputs.services.join(',') : '';
				reqParams.services_add_ons = (userInputs.services_add_ons && userInputs.services_add_ons.length > 0) ? userInputs.services_add_ons.join(',') : '';
				reqParams.contract_start_date = this.helper.dFormatToDB(userInputs.contract_start_date);
				reqParams.service_time_from = this.helper.tFormatToDBFrom24HoursFormat(userInputs.service_time_from);
				reqParams.service_time_to = this.helper.tFormatToDBFrom24HoursFormat(userInputs.service_time_to);
				reqParams.contract_service_cycles = userInputs.contract_service_cycles;
				reqParams.base_price = (userInputs.base_price) ? userInputs.base_price.toString().replace(/[^\d.-]/g, '') : 0;
				reqParams.suggested_price = (userInputs.suggested_price) ? userInputs.suggested_price.toString().replace(/[^\d.-]/g, '') : 0;
				reqParams.billing_status = userInputs.billing_status;
				reqParams.billing_type = userInputs.billing_type;
				reqParams.billing_manager_id = (this.selectedCustomer && this.selectedCustomer.is_billing_manager && this.selectedCustomer.is_billing_manager === 1) ? 0 : userInputs.billing_manager;
				reqParams.asked_price = userInputs.asked_price;
				reqParams.note = userInputs.note;

				const customerReqParams: any = {};
				customerReqParams.user_id = this.account.info.id;
				customerReqParams.auth_token = this.account.info.auth_token;
				customerReqParams.record_id = (this.selectedCustomer && +this.selectedCustomer.id) ? +this.selectedCustomer.id : 0;
				customerReqParams.action = (customerReqParams.record_id == 0) ? 'contract_customer_create' : 'contract_customer_update';
				if (customerReqParams.action == 'contract_customer_create') {
					customerReqParams.name = userInputs.name;
					customerReqParams.email = userInputs.email;
					customerReqParams.phone = userInputs.phone;
					customerReqParams.address = userInputs.customerAddress;
					customerReqParams.apartment = userInputs.customerApartment;
					customerReqParams.latitude = userInputs.customerLatitude;
					customerReqParams.longitude = userInputs.customerLongitude;
				}
				// customerReqParams.note = (this.selectedCustomer && this.selectedCustomer.note) ? this.selectedCustomer.note : '';
				customerReqParams.is_billing_manager = (this.selectedCustomer && this.selectedCustomer.is_billing_manager) ? this.selectedCustomer.is_billing_manager : 0;
				customerReqParams.billing_manager_id = (this.selectedCustomer && this.selectedCustomer.is_billing_manager && this.selectedCustomer.is_billing_manager === 1) ? 0 : userInputs.customerBillingManager;
				customerReqParams.billing_status = (this.selectedCustomer && this.selectedCustomer.billing_status !== '') ? this.selectedCustomer.billing_status : '';
				this.helper.makeAPIRequest('customers/save', customerReqParams).then((response) => {
					if (response.success === 1) {
						const record = response.data;
						this.selectedCustomer = this.copy(this.selectedCustomer, record);
						reqParams.customer = this.selectedCustomer.id;
						reqParams.name = userInputs.name;
						reqParams.email = userInputs.email;
						reqParams.phone = userInputs.phone;
						this.helper.makeAPIRequest('contracts/save', reqParams).then((result) => {
							this.isFormSubmitted = false;
							if (result.success === 1) {
								this.helper.showNotification('success', 'Contract info saved!', this.helper.config.defaultSuccessTitle);
								this.onClose.next({ contract: result.data.contract, oldContract: result.data.old_contract });
								this.bsModalRef.hide();
							} else if (result.error === 1) {
								this.isFormSubmitted = false;
								this.isProcessing = false;
								if (result.errorCode === 4) {
									this.helper.showNotification('danger', 'Number of service for this contract must be greater than or equals to ' + result.cnt_min_service + '.', this.helper.config.defaultErrorTitle);
								} else if (result.errorCode === 2) {
									this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
									this.bsModalRef.hide();
									this.account.logOut();
								} else {
									this.helper.showNotification('danger', 'API_ERROR ' + result.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
									this.isProcessing = false;
								}
							} else {
								this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
								this.isProcessing = false;
							}
						}).catch((httpError) => {
							this.isFormSubmitted = false;
							this.isProcessing = false;
							this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
						});
					} else if (response.error === 1) {
						this.isFormSubmitted = false;
						this.isProcessing = false;
						if (response.errorCode === 1 || response.errorCode === 1) {
							this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
							this.bsModalRef.hide();
							this.account.logOut();
						} else if (response.errorCode === 3) {
							this.helper.showNotification('danger', this.helper.config.defaultAccessibilityErrorMsg, this.helper.config.defaultAccessibilityErrorTitle);
							this.bsModalRef.hide();
							this.account.logOut();
						} else {
							this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
						}
					} else {
						this.isProcessing = false;
						this.isFormSubmitted = false;
						this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
					}
				}).catch((httpError) => {
					this.isFormSubmitted = false;
					this.isProcessing = false;
					this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
				});
			} else {
				this.isProcessing = false;
			}
		}, timeOutInterval);
	}

	/* Any changes in the code of this function should be reflected in the following:
		1) In the ***Similar method of TicketsAddEditComponent of this Web portal***.
		2) ***mobile/tickets/respond api in the server*** for updating the ticket pricing when the service gets updated.
		3) In the ***Mechanic app where there is service selection*** in place.
	*/
	updatePrices() {
		let basePrice = 0;
		const billingType = this.frmAddEdit.get('billing_type').value;
		if (billingType === 'hourly') {
			basePrice += +this.app.settings.service_hourly_rate;
		} else {
			const selectedServiceIDs = this.frmAddEdit.get('services').value;
			if (this.services && this.services.length > 0) {
				for (const serviceID of selectedServiceIDs) {
					const serviceIdx = this.helper.getArrayIndex(this.services, 'id', serviceID);
					if (serviceIdx !== false) {
						basePrice += +this.services[serviceIdx].price;
					}
				}
			}
			const selectedServiceAddOnIDs = this.frmAddEdit.get('services_add_ons').value;
			if (this.servicesAddOns && this.servicesAddOns.length > 0) {
				for (const serviceAddOnID of selectedServiceAddOnIDs) {
					const servicesAddOnIdx = this.helper.getArrayIndex(this.servicesAddOns, 'id', serviceAddOnID);
					if (servicesAddOnIdx !== false) {
						basePrice += +this.servicesAddOns[servicesAddOnIdx].price;
					}
				}
			}
		}
		this.frmAddEdit.controls['base_price'].setValue(basePrice);
		if (basePrice === 0) {
			this.frmAddEdit.controls['suggested_price'].setValue(basePrice);
		}
	}

	onCustomerChanged(event: any): void {
		setTimeout(() => {
			/* if (!this.selectedCustomer || event.target.value !== this.selectedCustomer.name) {
				this.frmAddEdit.controls['customer'].setValue('');
				this.selectedCustomer = undefined;
			} */
		}, 300);
	}

	onCustomerDropped($event, targetID: number) {
		const sourceId: number = +$event.source.getAttribute('data-id');
		let sourceCustomerIdx = this.helper.getArrayIndex(this.customersSuggestions, 'id', sourceId.toString());
		let targetCustomerIdx = this.helper.getArrayIndex(this.customersSuggestions, 'id', targetID.toString());
		if (sourceCustomerIdx !== false && targetCustomerIdx !== false) {
			this.mergeSourceCustomer = this.customersSuggestions[sourceCustomerIdx];
			this.mergeTargetCustomer = this.customersSuggestions[targetCustomerIdx];
			this.confirmCustomerMerge.show();
		}
	}

	mergeCustomers() {
		const reqParams: any = {};
		reqParams.user_id = this.account.info.id;
		reqParams.auth_token = this.account.info.auth_token;
		reqParams.source_id = +this.helper.getObjVal(this.mergeSourceCustomer, ['id'], 0);
		reqParams.target_id = +this.helper.getObjVal(this.mergeTargetCustomer, ['id'], 0);
		if (reqParams.source_id > 0 && reqParams.target_id > 0 && reqParams.source_id != reqParams.target_id) {
			this.helper.makeAPIRequest('customers/merge', reqParams).then(response => {
				if (response.success === 1) {
					let successMessage = "Customers";
					if (this.mergeSourceCustomer.name !== '' && this.mergeTargetCustomer.name !== '') {
						successMessage = this.mergeSourceCustomer.name + " & " + this.mergeTargetCustomer.name;
					}
					successMessage += " merged successfully."
					this.getCustomersSuggestions();
					this.helper.showNotification('success', successMessage, this.helper.config.defaultSuccessTitle);
				} else if (response.error === 1) {
					if (response.errorCode === 2) {
						this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
						this.account.logOut();
					} else {
						this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
					}
				} else {
					this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
				}
				this.mergeSourceCustomer = {};
				this.mergeTargetCustomer = {};
			})
		}
	}

	differenceInCalendarDays(date1: Date | any, date2: Date | any) {
		const diffTime = date2 - date1;
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		return diffDays;
	}


	isInvalidServiceDate = (date: Date): boolean => {
		/* const currDateTS = new Date().setHours(0, 0, 0, 0);
		let maxDateTS = (this.record.contract_start_date && this.record.contract_start_date !== '' && this.record.contract_start_date !== '0000-00-00') ? this.helper.dtFormatFromStrToObj(this.record.contract_start_date).getTime() : currDateTS;
		maxDateTS = (currDateTS < maxDateTS) ? currDateTS : maxDateTS;
		return this.differenceInCalendarDays(date, maxDateTS) > 0; */
		return false;
	}

	copy(mainObj, objectToCopy) {
		let key;

		// tslint:disable-next-line: forin
		for (key in mainObj) {
			objectToCopy[key] = mainObj[key];
		}
		return objectToCopy;
	}

	getCustomersSuggestions() {
		const userInputs: any = this.frmAddEdit.value;

		const reqParams: any = {};
		reqParams.user_id = this.account.info.id;
		reqParams.auth_token = this.account.info.auth_token;
		reqParams.address = userInputs.address;
		reqParams.apartment = userInputs.apartment;
		if (reqParams.address && reqParams.address.trim() !== '') {
			this.helper.makeAPIRequest('customers/lookup_by_address', reqParams).then(response => {
				this.customersSuggestions = [];
				if (response.success === 1) {
					response.data.record.forEach((customer: any) => {
						this.customersSuggestions.push(customer);
					});
				}
			});
		} else {
			this.customersSuggestions = [];
		}
	}

	onCustomerAddressChanged(address: string) {
		this.frmAddEdit.controls['customerAddress'].setValue(address);

		this.onAddressChanged(address);
		this.getCustomersSuggestions();
	}

	onCustomerApartmentChanged($event) {
		const apartment = $event.target.value;

		this.onApartmentChanged(apartment);
		this.getCustomersSuggestions();
	}

	onCustomerLatLongChanged(latLong: any) {
		this.frmAddEdit.controls['customerLatitude'].setValue(latLong.latitude);
		this.frmAddEdit.controls['customerLongitude'].setValue(latLong.longitude);

		this.onLatLongChanged(latLong);
	}

	onCustomerBillingManagerChanged(billingManager: number) {
		this.onBillingManagerChanged(billingManager);
	}

	onAddressChanged(address: string) {
		this.frmAddEdit.controls['address'].setValue(address);
	}

	onApartmentChanged(apartment: string) {
		this.frmAddEdit.controls['apartment'].setValue(apartment);
	}

	onBillingManagerChanged(billingManager: number) {
		if (this.billingManagers && this.billingManagers.length > 0) {
			const prevBillingManagerID = this.frmAddEdit.get('billing_manager').value;
			const prevBillingManagerIdx = this.helper.getArrayIndex(this.billingManagers, 'id', prevBillingManagerID);
			let prevBillingManager;
			if (prevBillingManagerIdx !== false) {
				prevBillingManager = this.billingManagers[prevBillingManagerIdx];
			}
			this.frmAddEdit.controls['billing_manager'].setValue(billingManager);
			setTimeout(() => {
				this.billingManagerSelected = null;
				const currCustomerName = this.frmAddEdit.get('name').value;
				if ((+billingManager) > 0) {
					// let defaultBillingStatus = 'yc_bill';
					const billingManagerIdx = this.helper.getArrayIndex(this.billingManagers, 'id', billingManager);
					if (billingManagerIdx !== false) {
						this.billingManagerSelected = this.billingManagers[billingManagerIdx];
						// defaultBillingStatus = (this.billingManagerSelected.billing_status && this.billingManagerSelected.billing_status !== '') ? 'yc_' + this.billingManagerSelected.billing_status : defaultBillingStatus;
						if (!currCustomerName || currCustomerName.trim() === '' || (prevBillingManager && currCustomerName.trim() === prevBillingManager.name.trim())) {
							this.record.name = this.billingManagerSelected.name.trim();
							this.frmAddEdit.controls['name'].setValue(this.record.name);
						}
					}
					// this.frmAddEdit.controls['billing_status'].setValue(defaultBillingStatus);
				} else if (currCustomerName && currCustomerName.trim() !== '' && prevBillingManager && currCustomerName.trim() === prevBillingManager.name.trim()) {
					this.record.name = '';
					this.frmAddEdit.controls['name'].setValue(this.record.name);
				}
			}, 10);
		}
	}

	onLatLongChanged(latLong: any) {
		this.frmAddEdit.controls['latitude'].setValue(latLong.latitude);
		this.frmAddEdit.controls['longitude'].setValue(latLong.longitude);
	}

	bindCustomerDetailsToContract(customer: any) {
		this.selectedCustomer = customer;
		this.record.name = this.selectedCustomer.name;
		this.record.email = this.selectedCustomer.email;
		this.record.phone = this.selectedCustomer.phone;
		this.record.customerAddress = this.selectedCustomer.address;
		this.record.customerApartment = this.selectedCustomer.apartment;
		this.record.customerLatitude = +this.selectedCustomer.latitude;
		this.record.customerLongitude = +this.selectedCustomer.longitude;
		this.record.customerBillingManager = +this.selectedCustomer.billing_manager;
		this.record.customer = this.selectedCustomer;

		this.frmAddEdit.controls['name'].setValue(this.selectedCustomer.name);
		this.frmAddEdit.controls['customerAddress'].setValue(this.selectedCustomer.address);
		this.frmAddEdit.controls['customerApartment'].setValue(this.selectedCustomer.apartment);
		this.frmAddEdit.controls['customerLatitude'].setValue(this.selectedCustomer.latitude);
		this.frmAddEdit.controls['customerLongitude'].setValue(this.selectedCustomer.longitude);
		this.frmAddEdit.controls['customerBillingManager'].setValue(this.helper.getObjVal(this.selectedCustomer, ['billing_manager', 'id'], null));

		if (this.record.customer.phone) {
			this.initPhoneFieldGroup(this.record.customer.phone);
		} else {
			this.addPhoneFieldGroup();
		}

		if (this.record.customer.email) {
			this.initEmailFieldGroup(this.record.customer.email);
		} else {
			this.addEmailFieldGroup();
		}

		this.record.address = this.selectedCustomer.address;
		this.record.apartment = this.selectedCustomer.apartment;
		this.record.latitude = this.selectedCustomer.latitude;
		this.record.longitude = this.selectedCustomer.longitude;
		this.record.billing_manager = this.selectedCustomer.billing_manager;
		this.frmAddEdit.controls['address'].setValue(this.selectedCustomer.address);
		this.frmAddEdit.controls['apartment'].setValue(this.selectedCustomer.apartment);
		this.frmAddEdit.controls['latitude'].setValue(this.selectedCustomer.latitude);
		this.frmAddEdit.controls['billing_manager'].setValue(this.helper.getObjVal(this.selectedCustomer, ['billing_manager', 'id'], ''));

		/* if ((!this.record || !this.record.id || this.record.id === 0) && this.selectedCustomer.billing_status && this.selectedCustomer.billing_status !== '' && (this.selectedCustomer.billing_status === 'bill' || this.selectedCustomer.billing_status === 'collect')) {
			this.record.billing_status = 'yc_' + this.selectedCustomer.billing_status;
			this.frmAddEdit.controls['billing_status'].setValue('yc_' + this.selectedCustomer.billing_status);
		} */

		this.customersSuggestions = [];
	}

	/**
	 * This method is used to set focus on invalid phone input. Type of phone from control is Array. This method will invalid phone input array index and set focus on that particular phone input.
	 */
	setFocusOnInvalidPhoneInput() {

		// Iterate through all the added phone number input by the user.
		for (let i = 0; i < this.frmAddEdit.controls['phone']['controls'].length; i++) {

			// Get current iterating phone input
			const currentControl = this.frmAddEdit.controls['phone']['controls'][i];

			/* Check if current phone input is valid or not.
			If invalid, we need to set focus on it
			*/
			if (currentControl.invalid) {
				this.phoneInputs.toArray()[i].nativeElement.focus();
				break;
			}
		}
	}

	/**
	* This method is used to set focus on invalid email input. Type of email from control is Array. This method will invalid email input array index and set focus on that particular email input.
	*/
	setFocusOnInvalidEmailInput() {

		// Iterate through all the added phone number input by the user.
		for (let i = 0; i < this.frmAddEdit.controls['email']['controls'].length; i++) {

			// Get current iterating email input
			const currentControl = this.frmAddEdit.controls['email']['controls'][i];

			/* Check if current email input is valid or not.
			If invalid, we need to set focus on it
			*/
			if (currentControl.invalid) {
				this.emailInputs.toArray()[i].nativeElement.focus();
				break;
			}
		}
	}
}

