import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { IdeateHelper } from '../core/services/ideate';
import { PusherService } from '../core/services/pusher/pusher.service';


@Injectable()
export class Account implements CanActivate {

	public info: any = {};
	public siteFeatures: any = {};
	public alertCount = 0;
	public getAlertInterval = null;
	private threadChannel: any;

	// features flags
	public optionalCustomerPhoneNumber = false;
	public optionalCustomerAddressForManagement = false;
	public hasBilling = false;

	constructor(
		private location: Location,
		private router: Router,
		public helper: IdeateHelper,
		private pusherService: PusherService
	) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		const allowedAccountType = this.helper.getObjVal(route.data, ['validations', 'account', 'type'], []);

		let siteToken: any = localStorage.getItem('siteToken');
		if (siteToken != null || siteToken != undefined) {
			this.helper.siteToken = siteToken;
		}

		let siteFeatures: any = localStorage.getItem('siteFeatures');
		siteFeatures = (this.helper.isValidJSON(siteFeatures)) ? JSON.parse(siteFeatures) : {};
		if (siteFeatures != null || siteFeatures != undefined) {
			this.siteFeatures = siteFeatures;
		}

		let userInfo = localStorage.getItem('currentUser');
		if (userInfo != null && userInfo !== undefined && this.helper.isValidJSON(userInfo)) {
			this.info = JSON.parse(userInfo);
		}

		if (this.siteFeatures && this.siteFeatures.optional_customer_phone_number) {
			this.optionalCustomerPhoneNumber = true;
		}

		if (this.siteFeatures && this.siteFeatures.optional_customer_address_for_management) {
			this.optionalCustomerAddressForManagement = true;
		}


		return new Promise((resolve, reject) => {
			this.requiredLogIn().then(() => {

				if (this.siteFeatures && this.siteFeatures.billing) {
					this.hasBilling = true;
				}

				if (allowedAccountType.indexOf(this.info.type) >= 0) {
					if (this.siteFeatures && this.siteFeatures.ticket_changes_approval_required) {
						// Subscribe to the channel
						this.getAlerts();
						this.threadChannel = this.pusherService.subscribe('ticket-channel');
						// Bind to the event
						this.pusherService.bindEvent(this.threadChannel, 'ticket-requested', (data: any) => {
							// console.log('New ticket requested:', data);
							this.getAlerts();
						});
					}
					resolve(true);
				} else {
					this.logOut();
					resolve(false);
				}
			})
				.catch((invalid) => {
					this.logOut();
					resolve(false);
				});
		});
	}

	requiredLogIn(): Promise<Boolean> {
		return new Promise((resolve, reject) => {
			if (this.helper.getObjVal(this.info, ['id']) !== '') {
				this.isAlreadyLoggedIn().then((result) => {
					if (result) {
						resolve(true);
					} else {
						resolve(false);
					}
					/* const currPath = this.location.path();
					this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
						this.router.navigateByUrl(currPath);
					}); */
					// resolve(this.info);
				})
					.catch(async (invalid) => {
						// this.logOut();
						await this.logoutAfter();
						// this.router.navigate(['login']);
						resolve(false);
					});
			} else {
				// console.log('test 2123');
				// resolve(this.info);
				resolve(false);
			}
		});
	}

	getAlerts() {
		return new Promise(async resolve => {
			const reqParams: any = {};
			reqParams.user_id = this.info.id;
			reqParams.auth_token = this.info.auth_token;
			this.helper.makeAPIRequest('tickets/get_alerts', reqParams).then((response) => {
				if (response.success === 1) {
					this.alertCount = response.data.pending_requests_count;
					return resolve(true);
				} else if (response.error === 1) {
					return resolve(false);
				} else {
					this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
					return resolve(false);
				}
			}).catch((httpError) => {
				this.helper.showNotification('danger', 'CONNECTIVITY_ERROR: ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
				return resolve(false);
			});
		});
	}

	isAlreadyLoggedIn() {
		return new Promise((resolve, reject) => {
			let info: any = localStorage.getItem('currentUser');
			info = (this.helper.isValidJSON(info)) ? JSON.parse(info) : {};
			if (!this.helper.isEmptyObj(info)) {
				this.verifyAuthToken(info.id, info.auth_token)
					.then((resVerification) => {
						if (resVerification) {
							// this.logIn(info);
							resolve(this.info);
						} else {
							resolve(false);
						}
					})
					.catch((ex) => {
						resolve(false);
					});
			} else {
				resolve(false);
			}
		});
	}

	logIn(user: any) {
		const settings = user.site_settings;
		delete user['site_settings'];
		this.info = user;
		localStorage.setItem('siteSettings', JSON.stringify(settings));
		localStorage.setItem('currentUser', JSON.stringify(this.info));
		localStorage.setItem('siteToken', this.helper.siteToken);
		localStorage.setItem('siteFeatures', JSON.stringify(user.siteFeatures));
	}

	async logOut() {
		const reqParams: any = {};
		reqParams.user_id = this.info.id;
		reqParams.auth_token = this.info.auth_token;
		this.helper.makeAPIRequest('account/logout', reqParams);
		await this.logoutAfter();
		this.router.navigate(['login']);
	}

	logoutAfter() {
		return new Promise<void>((resolve, reject) => {
			localStorage.removeItem('siteSettings');
			localStorage.removeItem('currentUser');
			localStorage.removeItem('siteToken');
			localStorage.removeItem('siteFeatures');
			this.info = {};
			(function clearAllTimers(): void {
				const highestId: any = setTimeout(() => { }, 0);
				for (let i = 0; i <= highestId; i++) {
					clearTimeout(i); // Clear timeout
					clearInterval(i); // Clear interval
				}
			})();
			resolve();
		})
	}

	verifyAuthToken(user_id: string, auth_token: string) {
		return new Promise((resolve, reject) => {
			const reqParams: any = {};
			reqParams.user_id = user_id;
			reqParams.auth_token = auth_token;
			this.helper.makeAPIRequest('account/validate_auth_token', reqParams).then((response) => {
				if (response.success === 1) {
					this.logIn(response.data.userdata);
					resolve(response);
				}
				resolve(false);
			}).catch((httpError) => {
				resolve(false);
			});
		});
	}
}