import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { IdeateHelper, IdeateValidators } from '../core/services/ideate';
import { Account } from '../providers/account';
import { App } from '../providers/app';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { BsLocaleService } from 'ngx-bootstrap';

@Component({
	selector: 'app-billing',
	templateUrl: './billing.component.html',
	styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {
	@ViewChild(DataTableDirective) dtElement: DataTableDirective;
	@ViewChild('datatable') dtElementRef: ElementRef;

	public filterPaymentStatus: string = '';
	public previousFilterPaymentStatus: string = '';
	public filterDateStart: Date | any;
	public filterDateEnd: Date | any;
	public filterStatuses: any[];
	public areDateFiltersEnabled = true;
	public selectedTicket: any = {};
	public showBillingForm = false;
	public cardCustomErrorMsg = '';
	public validationMessages: any = {
		payment_status: {
		},
		time_spent: {
		},
		billing_method: {
		},
		billing_price: {
		},
		billing_card_number: {
		},
		billing_card_expiry: {
		},
		billing_card_cvc: {
			maxLength: 'Invalid CVC.'
		},
		billing_snapshot: {
			allowedType: 'Invalid file type. Please select jpeg/jpg/png/gif files only.'
		},
		billing_description: {
		}
	};
	public ticketsHistoryMessage = '';
	public ticketsInfoListMessage = '';
	public syncTicketsInterval;
	public dtInitTimeout;
	public dtRowsLastUpdatedTS = 0;
	public dtRows: any[];
	public dtOptions: DataTables.Settings = {};
	public dtTrigger: Subject<any> = new Subject();
	public dtRequestParameters: any = {};
	public dtColsCount = 0;

	public frmBilling: FormGroup;

	public datePickerConfig = {
		isAnimated: true,
		dateInputFormat: 'MM-DD-YYYY',
		showWeekNumbers: false
	};
	public enableEditBillingInfo = false;


	constructor(
		private route: ActivatedRoute,
		private router: Router,
		public helper: IdeateHelper,
		public account: Account,
		private customValidators: IdeateValidators,
		public app: App,
		private formBuilder: FormBuilder,
		private localeService: BsLocaleService,
		private changeDetectorRef: ChangeDetectorRef,
	) { }

	async ngOnInit() {
		await new Promise(resolve => setTimeout(resolve, 50));
		if (!this.account.hasBilling) {
			this.router.navigate(['tickets']);
			return;
		}

		this.localeService.use('en');

		this.initFilterValues();

		this.dtColsCount = this.dtElementRef.nativeElement.children[0].children[0].children.length;
		this.route.paramMap.subscribe((params: ParamMap) => {
			this.initDataTable();
		});
	}

	initFilterValues() {
		if (this.app.ticketFilters.default === 1) {
			this.filterPaymentStatus = '';

			const days = 7; // Numbers of days prior to the current date that will be set as default for filterDateStart
			const date = new Date();
			const defaultFilterDateStart = new Date(date.getTime() - days * 24 * 60 * 60 * 1000);

			this.filterDateStart = defaultFilterDateStart;
			this.filterDateEnd = '';
			this.filterStatuses = ['completed'];
			this.areDateFiltersEnabled = true;
			this.areDateFiltersEnabled = true;
			this.filterStatuses = this.app.ticketStatusTypes;
			for (let i = 0; i < this.filterStatuses.length; i++) {
				if (['completed'].filter((item) => item === this.filterStatuses[i].value).length > 0) {
					this.filterStatuses[i].isSelected = true;
				} else {
					this.filterStatuses[i].isSelected = false;
				}
			}
		} else {
			this.filterPaymentStatus = this.app.ticketFilters.filterPaymentStatus;
			this.filterDateStart = this.app.ticketFilters.filterDateStart;
			this.filterDateEnd = this.app.ticketFilters.filterDateEnd;
			this.areDateFiltersEnabled = this.app.ticketFilters.areDateFiltersEnabled;
		}
	}

	resetFilters() {
		this.app.ticketFilters.default = 1;
		this.initFilterValues();
		this.onTicketsFilterUpdated();
	}

	onTicketsFilterUpdated() {
		this.app.ticketFilters.default = 0;
		this.app.ticketFilters.filterPaymentStatus = this.filterPaymentStatus;
		this.app.ticketFilters.filterDateStart = this.filterDateStart;
		this.app.ticketFilters.filterDateEnd = this.filterDateEnd;
		this.app.ticketFilters.areDateFiltersEnabled = this.areDateFiltersEnabled;
		this.app.ticketFilters.filterStatuses = this.filterStatuses;
		this.initDataTable(500);
	}

	initDataTable(timeOutInterval: number = 0) {
		this.stopTicketsSyncing();
		if (this.dtInitTimeout) {
			clearTimeout(this.dtInitTimeout);
		}
		this.dtInitTimeout = setTimeout(() => {
			this.dtRowsLastUpdatedTS = 0;
			this.dtRows = [];
			this.loadDatable();
		}, timeOutInterval);
	}

	stopTicketsSyncing() {
		if (this.syncTicketsInterval) {
			clearInterval(this.syncTicketsInterval);
		}
	}

	onPaymentStatusChange() {
		if (this.previousFilterPaymentStatus === this.filterPaymentStatus) {
			return;
		}

		this.onTicketsFilterUpdated();
		this.previousFilterPaymentStatus = this.filterPaymentStatus;
	}

	startTicketsSyncing() {
		this.stopTicketsSyncing();
		this.syncTicketsInterval = setInterval(() => {
			this.syncTickets();
		}, 30000);
	}

	syncTickets() {
		if (this.dtRowsLastUpdatedTS > 0) {
			this.dtRequestParameters.last_updated_at_ts = this.dtRowsLastUpdatedTS;
			this.helper.makeAPIRequest('tickets/list', this.dtRequestParameters).then((response) => {

				if (response.success === 1) {
					if (response.data.datatable.rows.length > 0) {
						const newTickets = response.data.datatable.rows;
						newTickets.forEach(newTicket => {
							if (newTicket.status === 'completed') {
								const index = this.dtRows.findIndex(dtRow => dtRow.id === newTicket.id);
								if (index !== -1) {
									this.dtRows[index] = newTicket;
								} else {
									this.dtRows.unshift(newTicket);
								}
							}
						});
						this.dtRowsLastUpdatedTS = response.data.last_updated_at_ts;
						this.dtRows = this.dtRows.filter(dtRow => dtRow.status === 'completed');
						this.changeDetectorRef.checkNoChanges();
					}
				} else if (response.error === 1) {
					if (response.errorCode === 2) {
						this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
						this.account.logOut();
					} else if (response.errorCode === 4) {
						// No records found.
					} else {
						this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
					}
				} else {
					this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
				}
			}).catch((httpError) => {
				this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
				console.log('connectivity error: ' + httpError);
			});
		}
	}

	loadDatable() {
		const reqParams: any = {};
		reqParams.user_id = this.account.info.id;
		reqParams.auth_token = this.account.info.auth_token;
		reqParams.last_updated_at_ts = this.dtRowsLastUpdatedTS;
		if (this.areDateFiltersEnabled) {
			reqParams.filter_start_date = this.helper.dFormatToDB(this.filterDateStart);
			reqParams.filter_end_date = this.helper.dFormatToDB(this.filterDateEnd);
		}

		reqParams.filter_payment_status = this.filterPaymentStatus;
		reqParams.filter_status = this.filterStatuses.filter((item: any) => item.isSelected).map((item: any) => item.value).join(',');

		this.ticketsInfoListMessage = 'Loading tickets...';

		this.selectedTicket = {};
		this.dtOptions = {
			pagingType: 'simple',
			lengthMenu: [[50, 100, 200, 500, 1000, -1], [50, 100, 200, 500, 1000, 'All']],
			pageLength: -1,
			serverSide: true,
			processing: false,
			searching: false,
			paging: false,
			dom: 'flrtp',
			ajax: (dtRequestParameters: any, callback) => {
				/* dtRequestParameters.async = false; */
				this.dtRequestParameters = Object.assign(dtRequestParameters, reqParams);
				this.helper.makeAPIRequest('tickets/list', this.dtRequestParameters).then((response) => {
					if (response.success === 1) {
						try {
							this.dtRows = response.data.datatable.rows;
							this.dtRowsLastUpdatedTS = response.data.last_updated_at_ts;
							this.startTicketsSyncing();
							if (this.dtRows.length > 0) {
								this.selectTicket(this.dtRows[0]);
								this.dtRows = this.dtRows.filter(dtRow => dtRow.status === 'completed');
							}
							callback({
								recordsTotal: response.data.datatable.recordsTotal,
								recordsFiltered: response.data.datatable.recordsFiltered,
								data: []
							});
							this.removeEmptyRow();
						} catch (error) {
							console.log(error);
						}
					} else if (response.error === 1) {
						if (response.errorCode === 2) {
							this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
							this.account.logOut();
						} else if (response.errorCode === 4) {
							this.ticketsInfoListMessage = this.helper.config.defaultNoResultErrorMsg;
							// No records found.
						} else {
							this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
						}
					} else {
						this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
					}
				}).catch((httpError) => {
					console.log('connectivity error: ' + httpError);
					this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
				});
			}
		};
		setTimeout(() => {
			if (this.dtElement.dtInstance) {
				this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
					dtInstance.destroy();
					this.dtTrigger.next();
					this.removeEmptyRow();
				});
			} else {
				this.dtTrigger.next();
				this.removeEmptyRow();
			}
			/* this.dtElement.dtInstance.then((dtInstance) => { setInterval(() => { dtInstance.ajax.reload(); }, 30000); }); */
		});
	}

	removeEmptyRow() {
		setTimeout(() => {
			const emptyRow = document.querySelector('.tickets-list td.dataTables_empty');

			if (this.dtRows.length > 0) {
				if (emptyRow) {
					emptyRow.parentElement.remove(); // Remove the entire row
				}
			} else {
				if (emptyRow) {
					emptyRow.parentElement.classList.add('empty_dt_record'); // Add class correctly
				}
			}
		}, 50);
	}

	selectTicket(ticket) {
		this.selectedTicket = ticket;
		if (
			typeof +this.selectedTicket.billing_method === 'number' &&
			+this.selectedTicket.billing_method > 0 &&
			this.selectedTicket.billing_txn_id !== ''
		) {
			this.enableEditBillingInfo = false;
		} else {
			this.enableEditBillingInfo = true;
		}

		if (+this.selectedTicket.billing_price == 0) {
			this.selectedTicket.billing_method = '';
		}

		this.initBillingForm();
	}

	initBillingForm() {
		this.showBillingForm = false;

		if (this.frmBilling) {
			this.frmBilling.reset();
		}

		this.frmBilling = this.formBuilder.group({
			payment_status: [(this.selectedTicket.payment_status && this.selectedTicket.payment_status !== '') ? this.selectedTicket.payment_status : 'unpaid'],
			time_spent: [this.helper.createDateObjectFromSeconds(this.helper.getObjVal(this.selectedTicket, ['time_spent'], 0))],
			billing_method: [this.selectedTicket.billing_method],
			billing_price: [this.selectedTicket.billing_price],
			billing_card_number: [this.selectedTicket.billing_card_number],
			billing_card_expiry: [this.selectedTicket.billing_card_expiry],
			billing_card_cvc: [this.selectedTicket.billing_card_cvc,
			this.selectedTicket.billing_method === 'card' ?
				Validators.compose([Validators.maxLength(4), Validators.minLength(3), Validators.required]) :
				Validators.nullValidator],
			billing_snapshot: ['', Validators.compose([this.customValidators.allowedType(['image/jpeg', 'image/jpg', 'image/png', 'image/gif'])])],
			billing_description: [this.selectedTicket.billing_description],
		});

		this.frmBilling.statusChanges.subscribe(() => {
			this.validateBillingForm();
		});

		// Add a listener for changes to 'billing_method' control
		this.frmBilling.get('billing_method').valueChanges.subscribe((billingMethod) => {
			const cvcControl = this.frmBilling.get('billing_card_cvc');
			if (billingMethod === 'card') {
				// If billing_method is 'new_card', add the validation for cvc
				cvcControl.setValidators([Validators.maxLength(4), Validators.minLength(3), Validators.required]);
			} else {
				// If it's a saved card, remove the validation for cvc
				this.cardCustomErrorMsg = '';
				cvcControl.setValidators(Validators.nullValidator);
			}
			// Update the validity of the cvc control
			cvcControl.updateValueAndValidity();
		});

		this.validateBillingForm();
	}

	validateBillingForm() {
		this.validationMessages = this.customValidators.getValidationErrors(this.frmBilling, this.validationMessages);
	}


	cardNumberChange(number) {
		// Remove any non-digit characters from the input
		let sanitizedNumber = number.replace(/\D/g, '');
		// Enforce maximum 16 digits limit
		if (sanitizedNumber.length > 16) {
			sanitizedNumber = sanitizedNumber.slice(0, 16);
			this.cardCustomErrorMsg = 'Please enter valid card number.';
		} else if (sanitizedNumber.length === 16 || sanitizedNumber.length === 15) {
			this.cardCustomErrorMsg = '';
		} else {
			this.cardCustomErrorMsg = 'Please enter valid card number.';
		}

		// Split the input into groups of four digits
		let formattedNumber = sanitizedNumber.replace(/(\d{4})/g, '$1 ');
		// Update the cardNumber property with the formatted value
		number = formattedNumber.trim();
		this.frmBilling.controls.billing_card_number.setValue(number);
	}

	cardExpChange(numberExp) {
		// Remove any non-digit characters from the input
		let sanitizedExp = numberExp.replace(/\D/g, '');
		if (sanitizedExp.length > 2) {
			numberExp = sanitizedExp.slice(0, 2) + '/' + sanitizedExp.slice(2, 4);
		}
		if (sanitizedExp.length === 4) {
			this.cardCustomErrorMsg = '';
		} else {
			this.cardCustomErrorMsg = 'Please enter valid card expiry.';
		}
		this.frmBilling.controls.billing_card_expiry.setValue(numberExp);
	}

	cardCVCChange(cvc) {
		if (cvc.length > 4) {
			this.frmBilling.controls.billing_card_cvc.setValue(cvc.slice(0, 4));
			return false;
		}
	}

	saveBilling(timeOutInterval: number = 0) {
		setTimeout(() => {
			if (this.frmBilling.valid && this.selectedTicket && this.selectedTicket.id > 0) {

				const userInputs: any = this.frmBilling.value;
				const reqParams: any = {};
				reqParams.user_id = this.account.info.id;
				reqParams.auth_token = this.account.info.auth_token;
				reqParams.record_id = this.selectedTicket.id;

				reqParams.payment_status = userInputs.payment_status;
				reqParams.time_spent = userInputs.time_spent === '' ? 0 : this.helper.convertDateObjectToSeconds(userInputs.time_spent);
				reqParams.billing_method = userInputs.billing_method;
				reqParams.billing_price = (userInputs.billing_price) ? userInputs.billing_price.toString().replace(/[^\d.-]/g, '') : 0;
				reqParams.billing_card_number = userInputs.billing_card_number;
				reqParams.billing_card_expiry = userInputs.billing_card_expiry;
				reqParams.billing_card_cvc = userInputs.billing_card_cvc;
				reqParams.billing_description = userInputs.billing_description;
				if (userInputs.billing_snapshot && userInputs.billing_snapshot.length > 0) {
					reqParams.billing_snapshot = userInputs.billing_snapshot[0];
				}

				this.helper.makeAPIRequest('tickets/save_billing_info', reqParams).then((response) => {
					if (response.success === 1) {
						this.showBillingForm = false;
						const ticket = response.data.ticket;
						this.showBillingForm = false;
						this.dtRows.map((t) => {
							if (t.id === this.selectedTicket.id) {
								t.payment_status = userInputs.payment_status;
								t.time_spent = ticket.time_spent;
								t.billing_method = ticket.billing_method;
								t.billing_price = userInputs.billing_price;
								t.billing_card_number = userInputs.billing_card_number;
								t.billing_card_expiry = userInputs.billing_card_expiry;
								t.billing_card_cvc = userInputs.billing_card_cvc;
								t.billing_description = userInputs.billing_description;
								t.billing_txn_id = ticket.billing_txn_id;
								t.customer = ticket.customer;
							}
						})
						if (userInputs.billing_method !== 'check' && userInputs.billing_method !== 'cash') {
							this.helper.showNotification('success', 'Payment done successfully!', this.helper.config.defaultSuccessTitle);
						} else {
							this.helper.showNotification('success', 'Billing info saved!', this.helper.config.defaultSuccessTitle);
						}
					} else if (response.error === 1) {
						if (response.errorCode === 2) {
							this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
							this.account.logOut();
						} else if ([5, 8, 10, 4, 6].includes(response.errorCode)) {
							this.helper.showNotification('danger', response.message, '');
						} else {
							this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
						}
					} else {
						this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
					}
				}).catch((httpError) => {
					this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
				});
			}
		}, timeOutInterval);
	}


}
