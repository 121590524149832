import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { IdeateHelper } from '../../services/ideate';
import { Account } from '../../../providers/account';
import { pagesToggleService } from './../../../@pages/services/toggler.service';
import { ModalDirective } from 'ngx-bootstrap';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TicketService } from '../../services/ticket/ticket.service';



@Component({
	selector: 'app-message-center',
	templateUrl: './message-center.component.html',
	styleUrls: ['./message-center.component.scss']
})
export class MessageCenterComponent implements OnInit, OnDestroy {

	@ViewChild('threadVideoModal') threadVideoModal: ModalDirective;
	@ViewChild('threadMediaShareViaEmailModal') threadMediaShareViaEmailModal: ModalDirective;
	@ViewChild('threadImageModal') threadImageModal: ModalDirective;


	public threads = [];
	public loading = false;
	public page = 1;
	public messageCenterToggle = false;
	public actionThread: any;
	public filterUsersList = [];
	public filterUserId = 'All';
	public filters: any = {};
	public isLastRecord = false;
	public filterRequestStatusVal = 'All';
	public requestStatusValues = [
		{
			value: '',
			label: 'All'
		},
		{
			value: 'pending',
			label: 'Pending'
		},
		{
			value: 'approved',
			label: 'Approved'
		},
		{
			value: 'rejected',
			label: 'Rejected'
		}
	];
	public onScrollTimeOut = null;
	public requestActionStatus = '';

	public selectedThreadImage = '';
	public selectedThreadVideoURL = '';
	public selectedThreadVoiceNote = '';
	public showImageShareOptions = false;
	public showVideoShareOptions = false;
	public showVoiceNoteShareOptions = false;
	public mediaEmailShareOptions: any = { email: '', subject: '', message: '', media_url: '' };

	private messageCenterSubscription: Subscription;

	constructor(
		public helper: IdeateHelper,
		public account: Account,
		private pagesToggleService: pagesToggleService,
		private router: Router,
		private elementRef: ElementRef,
		private ticketService: TicketService
	) { }

	ngOnInit() {
		// Subscribe to the MessageCenter observable
		this.messageCenterSubscription = this.pagesToggleService.MessageCenter.subscribe((toggle: boolean) => {
			this.messageCenterToggle = toggle;
			if (toggle) {

				if (this.account.alertCount > 0) {
					this.filterRequestStatusVal = 'pending';
					this.filters = {
						user_id: this.filterUserId,
						request_status: this.filterRequestStatusVal
					};
				}

				this.getThread(1);
			}
		});

		this.threadVideoModal.onShown.subscribe(() => {
			const video = this.elementRef.nativeElement.querySelector('video');
			video.load();
		});

		this.threadVideoModal.onHidden.subscribe(result => {
			this.showVideoShareOptions = false;
			this.selectedThreadVideoURL = '';
			const video = this.elementRef.nativeElement.querySelector('video');
			if (video) {
				video.pause();
			}
		});

		this.threadImageModal.onHidden.subscribe(result => {
			this.showImageShareOptions = false;
		});
	}

	ngOnDestroy() {
		this.threadImageModal.hide();
		this.threadVideoModal.hide();
		if (this.messageCenterSubscription) {
			this.messageCenterSubscription.unsubscribe();
		}
	}

	resetMessageCenter() {
		return new Promise(resolve => {
			const element = document.querySelector('.message-center-list');
			if (element && element != null) {
				element.scrollTop = 0;
			}
			this.page = 1;
			this.threads = [];
			this.isLastRecord = false;
			return resolve(true);
		})
	}

	onScroll() {
		if (this.onScrollTimeOut != null) {
			clearTimeout(this.onScrollTimeOut);
		}
		this.onScrollTimeOut = setTimeout(() => {
			const element = document.querySelector('.message-center-list');
			if ((element.scrollTop + element.clientHeight + 5) >= element.scrollHeight && this.messageCenterToggle && !this.isLastRecord && !this.loading) {
				this.page++;
				this.getThread(this.page);
			}
		}, 100);
	}


	getThread(page = 1) {
		return new Promise(async resolve => {
			if (page === 1) {
				await this.resetMessageCenter();
			}

			this.loading = true;
			const reqParams: any = {};
			reqParams.user_id = this.account.info.id;
			reqParams.auth_token = this.account.info.auth_token;

			reqParams.page = page;
			reqParams.filters = this.filters;

			// Store the current scroll position
			let scrollTop = 0
			const element = document.querySelector('.message-center-list');
			if (element && element != null) {
				element.scrollTop = 0;
				scrollTop = element.scrollTop;
			}

			this.helper.makeAPIRequest('tickets/get_threads', reqParams).then((response) => {
				this.loading = false;

				if (response.success === 1) {
					if (this.page == 1) {
						this.threads = response.data.threads;
						this.filterUsersList = response.data.threads_users;
					} else {
						this.threads = this.threads.concat(response.data.threads);
					}
					// Restore the scroll position
					if (element && element != null) {
						element.scrollTop = scrollTop + (element.scrollHeight - (element.scrollTop + element.clientHeight));
					}

					this.isLastRecord = response.data.is_last_record;

					return resolve(true);
				} else if (response.error === 1) {
					return resolve(false);
				} else {
					this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
					return resolve(false);
				}
			}).catch((httpError) => {
				this.helper.showNotification('danger', 'CONNECTIVITY_ERROR: ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
				return resolve(false);
			});
		});
	}


	showThreadMediaShareViaEmailModal(mediaURL: string) {
		if (mediaURL) {
			this.mediaEmailShareOptions.email = (this.actionThread && this.actionThread.customer && this.actionThread.customer.email) ? this.actionThread.customer.email : '';
			this.mediaEmailShareOptions.subject = '';
			this.mediaEmailShareOptions.message = '';
			this.mediaEmailShareOptions.media_url = mediaURL;
			this.threadMediaShareViaEmailModal.show();
		} else {
			this.helper.showNotification('danger', this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
		}
	}

	shareThreadMediaViaEmail() {
		if (this.mediaEmailShareOptions.email !== '' && this.mediaEmailShareOptions.media_url !== '') {
			const reqParams: any = {};
			reqParams.user_id = this.account.info.id;
			reqParams.auth_token = this.account.info.auth_token;
			reqParams.media_url = this.mediaEmailShareOptions.media_url;
			reqParams.email_id = this.mediaEmailShareOptions.email;
			reqParams.subject = this.mediaEmailShareOptions.subject;
			reqParams.message = this.mediaEmailShareOptions.message;
			this.helper.makeAPIRequest('tickets/share_media_via_email', reqParams).then((response) => {
				if (response.success === 1) {
					this.threadMediaShareViaEmailModal.hide();
				} else if (response.error === 1) {
					if (response.errorCode === 2) {
						this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
						this.account.logOut();
					} else {
						this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
					}
				} else {
					this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
				}
			}).catch((httpError) => {
				this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
			});
		}
	}


	serviceChangeAction(requestId: number, status: string, note) {
		this.ticketService.ticketRequestAction(requestId, status, note, this.actionThread).then((response: any) => {
			if (response.success === 1) {
				this.getThread(1);
				this.ticketService.emitSelectedTicketUpdateEvent(response);
			}
		});
	}

	onFilterChange() {
		this.filters = {
			user_id: this.filterUserId,
			request_status: this.filterRequestStatusVal
		};
		this.getThread(1);
	}

	viewThreadTicket(thread, $event) {
		if ($event.target.classList.contains('no-click-action') ||
			$event.target.closest('.no-click-action') ||
			!thread.ticket_id
		) {
			return false;
		}
		this.pagesToggleService.toggleMessageCenter(false);
		this.router.navigate(['/tickets/view', thread.ticket_id]);
	}
}
